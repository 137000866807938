import {configureStore, createSlice} from "@reduxjs/toolkit";

const boothInfo = createSlice({
  name: "boothInfo",
  initialState: {data: {}},
  reducers: {
    setBoothInfoDefault: (state, action) => {
      state.data = action.payload;
    }
  }
})
export const {setBoothInfoDefault} = boothInfo.actions;

const boothCircle = createSlice({
  name: "boothCircle",
  initialState: {data: {}},
  reducers: {
    setBoothCircle: (state, action) => {
      state.data = action.payload;
    }
  }
})
export const {setBoothCircle} = boothCircle.actions;

const boothModalInfo = createSlice({
  name: "boothModalInfo",
  initialState: {clickTime: 0, boothName: null, boothId: null},
  reducers: {
    setBoothInfo: (state, action) => {
      if (action.payload !== "") {
        state.clickTime = Date.now();
        state.boothName = action.payload;
      }
    }
  }
})
export const {setBoothInfo} = boothModalInfo.actions;

export default configureStore({
  reducer: {
    boothInfo: boothInfo.reducer,
    boothCircle: boothCircle.reducer,
    boothModalInfo: boothModalInfo.reducer,
  }
})