import './App.css';
import Grid from "./components/Grid";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {setBoothCircle, setBoothInfoDefault} from "./store";
import axios from "axios";

function App() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [_, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        axios.get("https://boothmoa.s3.amazonaws.com/booth_info.json")
          .then(res => dispatch((setBoothInfoDefault(res.data))))
          .catch(() => console.log("error"))
        axios.get("https://boothmoa.s3.amazonaws.com/booth_circle.json")
          .then(res => dispatch((setBoothCircle(res.data))))
          .catch(() => console.log("error"))
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return (
      <div className="App">
      </div>
    )
  }

  return (
    <div className="App">
      <Grid/>
    </div>
  );
}

export default App;
