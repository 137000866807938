import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {Select, Space} from "antd";

const SearchField = (props) => {
  const [optionData, setOptionData] = useState([])
  const [selectedBooth, setSelectedBooth] = useState("")
  const boothCircle = useSelector((state) => state.boothCircle);

  useEffect(() => {
    const boothNames = Object.keys(boothCircle.data);
    const newOptions = boothNames.map((value) => ({value: value, label: value}))
    setOptionData(
      newOptions
    );
  }, [boothCircle]);

  return (
    <div>
      <Space style={{ width: 500 }}>
        <Select size={"large"} style={{ width: 300 }}
                showSearch
                placeholder="부스명 검색하기"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={optionData}
                onChange={(value) => setSelectedBooth(value)}
        />
        {Object.keys(boothCircle.data).includes(selectedBooth) ? boothCircle.data[selectedBooth]["boothNums"][props.day] : ""}
      </Space>
    </div>
  );
};

export default SearchField;
