import React, {useState, useRef, useEffect} from 'react';
import bg from './../bg.png';
import './Grid.css';
import Booth10Vertical from "./Booth10Vertical";
import BoothLargeVertical from "./BoothLargeVertical";
import BoothLargeHorizontal from "./BoothLargeHorizontal";
import Booth1 from "./Booth1";
import booth_info from "../booth_info.json"
import {Button, Dropdown, Modal, Space} from "antd";
import {MenuProps} from "antd";
import {useSelector} from "react-redux";
import SearchField from "./SearchField";

const Grid = () => {
  const [isInitialRender, setIsInitialRender] = useState(true);

  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({x: 0, y: 0});
  const dragStartPosition = useRef({x: 0, y: 0});

  const [day, setDay] = useState("토요일");
  const [modalOn, setModalOn] = useState(false);
  const imageRef = useRef(null);

  const boothCircle = useSelector((state) => state.boothCircle);
  const boothModalInfo = useSelector((state) => state.boothModalInfo);

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      return;
    }
    setModalOn(true);
  }, [boothModalInfo]);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    dragStartPosition.current = {
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    };
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      let newX = e.clientX - dragStartPosition.current.x;
      let newY = e.clientY - dragStartPosition.current.y;
      let rect = imageRef.current.getBoundingClientRect();

      if (newX > 0) newX = 0;
      if (newY > 0) newY = 0;

      if (newX < -(rect.width - window.innerWidth)) newX = -(rect.width - window.innerWidth);
      if (newY < -(rect.height - window.innerHeight)) newY = -(rect.height - window.innerHeight);

      setPosition({
        x: newX,
        y: newY,
      });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleTouchStart = (e) => {
    setIsDragging(true);
    const touch = e.touches[0];
    dragStartPosition.current = {
      x: touch.clientX - position.x,
      y: touch.clientY - position.y,
    };
  };

  const handleTouchMove = (e) => {
    if (isDragging) {
      const touch = e.touches[0];
      let newX = touch.clientX - dragStartPosition.current.x;
      let newY = touch.clientY - dragStartPosition.current.y;
      let rect = imageRef.current.getBoundingClientRect();

      if (newX > 0) newX = 0;
      if (newY > 0) newY = 0;

      if (newX < -(rect.width - window.innerWidth))
        newX = -(rect.width - window.innerWidth);
      if (newY < -(rect.height - window.innerHeight))
        newY = -(rect.height - window.innerHeight);

      setPosition({
        x: newX,
        y: newY,
      });
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  const items: MenuProps['items'] = [
    {key: '1', label: (
        <a target="_blank" onClick={() => setDay("토요일")}>
          토요일
        </a>
      ),},
    {key: '2', label: (
        <a target="_blank" onClick={() => setDay("일요일")}>
          일요일
        </a>
      ),},
  ];

  return <div
    className="container"
    onMouseMove={handleMouseMove}
    onMouseUp={handleMouseUp}
    onMouseLeave={handleMouseUp}
    onTouchMove={handleTouchMove}
    onTouchEnd={handleTouchEnd}
  >
    <div>
      <img src={bg} alt="booth" ref={imageRef}
           className="draggable-image" style={{left: position.x, top: position.y}}
           onMouseDown={handleMouseDown} onTouchStart={handleTouchStart}
           onDragStart={(e) => e.preventDefault()}/>

      <div className="overlay" style={{left: position.x + 640, top: position.y + 38}}>
        <Space direction="vertical">
          <Space wrap>
            <Dropdown menu={{ items }} placement="bottomLeft" arrow>
              <Button size={"large"}>{day}</Button>
            </Dropdown>
          </Space>
        </Space>
      </div>

      <div className="overlay" style={{left: position.x + 750, top: position.y + 38}}>
        <SearchField day={day}/>
      </div>

      <BoothLargeVertical position={position} left={45} top={320} data={booth_info[day]} boothId={"Z1-6"}/>
      <BoothLargeVertical position={position} left={45} top={490} data={booth_info[day]} boothId={"Z1-5"}/>
      <BoothLargeVertical position={position} left={45} top={660} data={booth_info[day]} boothId={"Z1-4"}/>
      <BoothLargeVertical position={position} left={45} top={830} data={booth_info[day]} boothId={"Z1-3"}/>
      <BoothLargeVertical position={position} left={45} top={1000} data={booth_info[day]} boothId={"Z1-2"}/>
      <BoothLargeVertical position={position} left={45} top={1170} data={booth_info[day]} boothId={"Z1-1"}/>
      <BoothLargeHorizontal position={position} left={475} top={300} data={booth_info[day]} boothId={"Z1-7"}/>
      <BoothLargeHorizontal position={position} left={615} top={300} data={booth_info[day]} boothId={"Z1-8"}/>

      <Booth10Vertical position={position} left={127} top={390} data={booth_info[day]}
                       boothIds={["A20", "A19", "A18", "A17", "A16", "A15", "A14", "A13", "A12", "A11"]}/>
      <Booth10Vertical position={position} left={127} top={390 + 445} data={booth_info[day]}
                       boothIds={["A10", "A09", "A08", "A07", "A06", "A05", "A04", "A03", "A02", "A01"]}/>
      <Booth10Vertical position={position} left={127+40} top={390} data={booth_info[day]}
                       boothIds={["B20", "B19", "B18", "B17", "B16", "B15", "B14", "B13", "B12", "B11"]}/>
      <Booth10Vertical position={position} left={127+40} top={390+445} data={booth_info[day]}
                       boothIds={["B10", "B09", "B08", "B07", "B06", "B05", "B04", "B03", "B02", "B01"]}/>

      <Booth10Vertical position={position} left={239} top={390} data={booth_info[day]}
                       boothIds={["C20", "C19", "C18", "C17", "C16", "C15", "C14", "C13", "C12", "C11"]}/>
      <Booth10Vertical position={position} left={239} top={390+445} data={booth_info[day]}
                       boothIds={["C10", "C09", "C08", "C07", "C06", "C05", "C04", "C03", "C02", "C01"]}/>
      <Booth10Vertical position={position} left={239+40} top={390} data={booth_info[day]}
                       boothIds={["D20", "D19", "D18", "D17", "D16", "D15", "D14", "D13", "D12", "D11"]}/>
      <Booth10Vertical position={position} left={239+40} top={390+445} data={booth_info[day]}
                       boothIds={["D10", "D09", "D08", "D07", "D06", "D05", "D04", "D03", "D02", "D01"]}/>

      <Booth10Vertical position={position} left={351} top={390} data={booth_info[day]}
                       boothIds={["E20", "E19", "E18", "E17", "E16", "E15", "E14", "E13", "E12", "E11"]}/>
      <Booth10Vertical position={position} left={351} top={390+445} data={booth_info[day]}
                       boothIds={["E10", "E09", "E08", "E07", "E06", "E05", "E04", "E03", "E02", "E01"]}/>
      <Booth10Vertical position={position} left={351+40} top={390} data={booth_info[day]}
                       boothIds={["F20", "F19", "F18", "F17", "F16", "F15", "F14", "F13", "F12", "F11"]}/>
      <Booth10Vertical position={position} left={351+40} top={390+445} data={booth_info[day]}
                       boothIds={["F10", "F09", "F08", "F07", "F06", "F05", "F04", "F03", "F02", "F01"]}/>

      <Booth10Vertical position={position} left={463} top={390} data={booth_info[day]}
                       boothIds={["G20", "G19", "G18", "G17", "G16", "G15", "G14", "G13", "G12", "G11"]}/>
      <Booth10Vertical position={position} left={463} top={390+445} data={booth_info[day]}
                       boothIds={["G10", "G09", "G08", "G07", "G06", "G05", "G04", "G03", "G02", "G01"]}/>
      <Booth10Vertical position={position} left={463+40} top={390} data={booth_info[day]}
                       boothIds={["Vir60", "Vir59", "Vir58", "Vir57", "Vir56", "Vir55", "Vir54", "Vir53", "Vir52", "Vir51"]}/>
      <Booth10Vertical position={position} left={463+40} top={390+445} data={booth_info[day]}
                       boothIds={["Vir50", "Vir49", "Vir48", "Vir47", "Vir46", "Vir45", "Vir44", "Vir43", "Vir42", "Vir41"]}/>

      <Booth10Vertical position={position} left={575} top={390} data={booth_info[day]}
                       boothIds={["Vir40", "Vir39", "Vir38", "Vir37", "Vir36", "Vir35", "Vir34", "Vir33", "Vir32", "Vir31"]}/>
      <Booth10Vertical position={position} left={575} top={390+445} data={booth_info[day]}
                       boothIds={["Vir30", "Vir29", "Vir28", "Vir27", "Vir26", "Vir25", "Vir24", "Vir23", "Vir22", "Vir21"]}/>
      <Booth10Vertical position={position} left={575+40} top={390} data={booth_info[day]}
                       boothIds={["Vir20", "Vir19", "Vir18", "Vir17", "Vir16", "Vir15", "Vir14", "Vir13", "Vir12", "Vir11"]}/>
      <Booth10Vertical position={position} left={575+40} top={390+445} data={booth_info[day]}
                       boothIds={["Vir10", "Vir09", "Vir08", "Vir07", "Vir06", "Vir05", "Vir04", "Vir03", "Vir02", "Vir01"]}/>

      <Booth10Vertical position={position} left={687} top={390} data={booth_info[day]}
                       boothIds={["Cre20", "Cre19", "Cre18", "Cre17", "Cre16", "Cre15", "Cre14", "Cre13", "Cre12", "Cre11"]}/>
      <Booth10Vertical position={position} left={687} top={390+445} data={booth_info[day]}
                       boothIds={["Cre10", "Cre09", "Cre08", "Cre07", "Cre06", "Cre05", "Cre04", "Cre03", "Cre02", "Cre01"]}/>


      <Booth10Vertical position={position} left={1725} top={390} data={booth_info[day]}
                       boothIds={["H20", "H19", "H18", "H17", "H16", "H15", "H14", "H13", "H12", "H11"]}/>
      <Booth10Vertical position={position} left={1725} top={390+445} data={booth_info[day]}
                       boothIds={["H10", "H09", "H08", "H07", "H06", "H05", "H04", "H03", "H02", "H01"]}/>

      <Booth10Vertical position={position} left={1797} top={390} data={booth_info[day]}
                       boothIds={["I20", "I19", "I18", "I17", "I16", "I15", "I14", "I13", "I12", "I11"]}/>
      <Booth10Vertical position={position} left={1797} top={390+445} data={booth_info[day]}
                       boothIds={["I10", "I09", "I08", "I07", "I06", "I05", "I04", "I03", "I02", "I01"]}/>
      <Booth10Vertical position={position} left={1797+40} top={390} data={booth_info[day]}
                       boothIds={["J20", "J19", "J18", "J17", "J16", "J15", "J14", "J13", "J12", "J11"]}/>
      <Booth10Vertical position={position} left={1797+40} top={390+445} data={booth_info[day]}
                       boothIds={["J10", "J09", "J08", "J07", "J06", "J05", "J04", "J03", "J02", "J01"]}/>

      <Booth10Vertical position={position} left={1909} top={390} data={booth_info[day]}
                       boothIds={["K20", "K19", "K18", "K17", "K16", "K15", "K14", "K13", "K12", "K11"]}/>
      <Booth10Vertical position={position} left={1909} top={390+445} data={booth_info[day]}
                       boothIds={["K10", "K09", "K08", "K07", "K06", "K05", "K04", "K03", "K02", "K01"]}/>
      <Booth10Vertical position={position} left={1909+40} top={390} data={booth_info[day]}
                       boothIds={["L20", "L19", "L18", "L17", "L16", "L15", "L14", "L13", "L12", "L11"]}/>
      <Booth10Vertical position={position} left={1909+40} top={390+445} data={booth_info[day]}
                       boothIds={["L10", "L09", "L08", "L07", "L06", "L05", "L04", "L03", "L02", "L01"]}/>

      <Booth10Vertical position={position} left={2021} top={390} data={booth_info[day]}
                       boothIds={["M20", "M19", "M18", "M17", "M16", "M15", "M14", "M13", "M12", "M11"]}/>
      <Booth10Vertical position={position} left={2021} top={390+445} data={booth_info[day]}
                       boothIds={["M10", "M09", "M08", "M07", "M06", "M05", "M04", "M03", "M02", "M01"]}/>
      <Booth10Vertical position={position} left={2021+40} top={390} data={booth_info[day]}
                       boothIds={["N20", "N19", "N18", "N17", "N16", "N15", "N14", "N13", "N12", "N11"]}/>
      <Booth10Vertical position={position} left={2021+40} top={390+445} data={booth_info[day]}
                       boothIds={["N10", "N09", "N08", "N07", "N06", "N05", "N04", "N03", "N02", "N01"]}/>

      <Booth10Vertical position={position} left={2133} top={390} data={booth_info[day]}
                       boothIds={["O20", "O19", "O18", "O17", "O16", "O15", "O14", "O13", "O12", "O11"]}/>
      <Booth10Vertical position={position} left={2133} top={390+445} data={booth_info[day]}
                       boothIds={["O10", "O09", "O08", "O07", "O06", "O05", "O04", "O03", "O02", "O01"]}/>
      <Booth10Vertical position={position} left={2133+40} top={390} data={booth_info[day]}
                       boothIds={["Voc20", "Voc19", "Voc18", "Voc17", "Voc16", "Voc15", "Voc14", "Voc13", "Voc12", "Voc11"]}/>
      <Booth10Vertical position={position} left={2133+40} top={390+445} data={booth_info[day]}
                       boothIds={["Voc10", "Voc09", "Voc08", "Voc07", "Voc06", "Voc05", "Voc04", "Voc03", "Voc02", "Voc01"]}/>

      <Booth10Vertical position={position} left={2245} top={390} data={booth_info[day]}
                       boothIds={["Voc21", "Voc22", "Voc23", "Voc24", "Voc25", "Voc26", "Voc27", "Voc28", "Voc29", "Voc30"]}/>
      <Booth10Vertical position={position} left={2245} top={390+445} data={booth_info[day]}
                       boothIds={["Voc31", "Voc32", "Voc33", "Voc34", "Voc35", "Voc36", "Voc37", "Voc38", "Voc39", "Voc40"]}/>
      <Booth10Vertical position={position} left={2245+40} top={390} data={booth_info[day]}
                       boothIds={["Voc60", "Voc59", "Voc58", "Voc57", "Voc56", "Voc55", "Voc54", "Voc53", "Voc52", "Voc51"]}/>
      <Booth10Vertical position={position} left={2245+40} top={390+445} data={booth_info[day]}
                       boothIds={["Voc50", "Voc49", "Voc48", "Voc47", "Voc46", "Voc45", "Voc44", "Voc43", "Voc42", "Voc41"]}/>

      <Booth10Vertical position={position} left={2357} top={300} data={booth_info[day]}
                       boothIds={["Voc90", "Voc89", "Voc88", "Voc87", "Voc86", "Voc85", "Voc84", "Voc83", "Voc82", "Voc81"]}/>
      <Booth10Vertical position={position} left={2357} top={670} data={booth_info[day]}
                       boothIds={["Voc80", "Voc79", "Voc78", "Voc77", "Voc76", "Voc75", "Voc74", "Voc73", "Voc72", "Voc71"]}/>
      <Booth10Vertical position={position} left={2357} top={1040} data={booth_info[day]}
                       boothIds={["Voc70", "Voc69", "Voc68", "Voc67", "Voc66", "Voc65", "Voc64", "Voc63", "Voc62", "Voc61"]}/>

      <BoothLargeHorizontal position={position} left={1750} top={300} data={booth_info[day]} boothId={"Z3-1"}/>
      <BoothLargeHorizontal position={position} left={1750+140} top={300} data={booth_info[day]} boothId={"Z3-2"}/>
      <Booth1 position={position} left={2180} top={300} data={booth_info[day]} boothId={"Voc94"}/>
      <Booth1 position={position} left={2180+35} top={300} data={booth_info[day]} boothId={"Voc93"}/>
      <Booth1 position={position} left={2180+70} top={300} data={booth_info[day]} boothId={"Voc92"}/>
      <Booth1 position={position} left={2180+105} top={300} data={booth_info[day]} boothId={"Voc91"}/>

    </div>

    {
      modalOn ? <Modal
        title={boothModalInfo.boothName}
        centered
        open={modalOn}
        onOk={() => setModalOn(false)}
        onCancel={() => setModalOn(false)}
        footer={[
          <Button key="cancel" onClick={() => setModalOn(false)}>
            닫기
          </Button>,
          <Button
            key="navigate"
            type="primary"
            onClick={() => boothCircle.data[boothModalInfo.boothName]["link"] !== ""
              ? window.open(boothCircle.data[boothModalInfo.boothName]["link"]) : null}
            disabled={boothCircle.data[boothModalInfo.boothName]["link"] === ""}
          >
            자세히
          </Button>,
        ]}
      >
        <p>부스 번호: {boothCircle.data[boothModalInfo.boothName]["boothNums"][day].join(' ')}</p>
        {/*<p>작가: {boothCircle.data[boothModalInfo.boothName]["link"]}</p>*/}
      </Modal> : null
    }

  </div>;
};

export default Grid;
