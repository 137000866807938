import React from "react";
import {useDispatch} from "react-redux";
import {setBoothInfo} from "../store";

const BoothFragment = (props) => {
  const dispatch = useDispatch();

  return (
    <>
      {
        props.data[props.boothId]
          ? <td style={{width: props.width ? props.width : '30px', height: props.height ? props.height : '30px', cursor: 'pointer'}}
                onClick={() => {
                  dispatch(setBoothInfo(props.data[props.boothId]));
                }}>
            {props.boothId.replace(/[a-zA-Z]/g, '')}
          </td>
          : <td style={{width: props.width ? props.width : '30px', height: props.height ? props.height : '30px'}}></td>
      }
    </>
  )
}

export default BoothFragment;