import React from "react";
import BoothFragment from "./BoothFragment";

const Booth1 = (props) => {
  return (
    <table className="overlay-table"
           style={{
             left: props.position.x + props.left,
             top: props.position.y + props.top
           }}>
      <tbody>
      <tr style={{width: props.width ? props.width : '30px', height: props.height ? props.height : '30px'}}>
        <BoothFragment {...props}/>
      </tr>
      </tbody>
    </table>
  )
}

export default Booth1;