import React from "react";
import BoothFragment from "./BoothFragment";

const Booth10Vertical = (props) => {
  return (
    <table className="overlay-table"
           style={{
             left: props.position.x + props.left,
             top: props.position.y + props.top
           }}>
      <tbody>
      {
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => {
          const newProps = {...props}
          newProps.boothId = props.boothIds[index];

          return <tr style={{width: props.width ? props.width : '30px', height: props.height ? props.height : '30px'}}>
            <BoothFragment {...newProps}></BoothFragment>
          </tr>
        })
      }
      </tbody>
    </table>
  )
}

export default Booth10Vertical;